var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.dialogVisible,
            width: "62%",
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.resetForm,
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("h2", { staticClass: "title" }, [
                _c("div", { staticClass: "title_icon" }),
                _vm._v(
                  " " + _vm._s(_vm.type == "edit" ? "编辑" : "添加") + "通道 "
                ),
              ]),
              _c(
                "el-form",
                {
                  ref: "form",
                  staticStyle: { padding: "0" },
                  attrs: {
                    inline: true,
                    rules: _vm.rules,
                    "label-width": "140px",
                    model: _vm.form,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "通道名称：", prop: "parkChannelName" } },
                    [
                      _c("el-input", {
                        staticStyle: {},
                        attrs: { placeholder: "请输入通道名称" },
                        model: {
                          value: _vm.form.parkChannelName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "parkChannelName", $$v)
                          },
                          expression: "form.parkChannelName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.type == "edit"
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "通道编码：",
                            prop: "parkChannelCode",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: {},
                            attrs: {
                              disabled: true,
                              placeholder: "请输入通道编码",
                            },
                            model: {
                              value: _vm.form.parkChannelCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "parkChannelCode", $$v)
                              },
                              expression: "form.parkChannelCode",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { label: "出入口类型：", prop: "channelType" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: {},
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.channelType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "channelType",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.channelType",
                          },
                        },
                        _vm._l(_vm.channelList, function (value) {
                          return _c("el-option", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: value.code === 1 || value.code === 2,
                                expression:
                                  "value.code === 1 || value.code === 2",
                              },
                            ],
                            key: value.code,
                            attrs: { label: value.desc, value: value.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("h2", { staticClass: "title" }, [
                _c("div", { staticClass: "title_icon" }),
                _vm._v(" 关联AI设备 "),
              ]),
              _c("el-transfer", {
                attrs: {
                  "target-order": "push",
                  titles: ["未选择", "已选择"],
                  filterable: "",
                  props: {
                    key: "equipmentId",
                    label: "equipmentName",
                  },
                  "filter-placeholder": "请输入AI设备名称",
                  data: _vm.equipmentList,
                },
                model: {
                  value: _vm.selected,
                  callback: function ($$v) {
                    _vm.selected = $$v
                  },
                  expression: "selected",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.submitData } },
                [_vm._v(_vm._s(_vm.$t("button.preservation")))]
              ),
              _c("el-button", { on: { click: _vm.resetForm } }, [
                _vm._v("返回"),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }