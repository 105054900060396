var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "",
            visible: _vm.dialogVisible,
            width: "62%",
            "custom-class": "dialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
            close: _vm.resetForm,
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [
              _c("h2", { staticClass: "title" }, [
                _c("div", { staticClass: "title_icon" }),
                _vm._v("查看通道 "),
              ]),
              _c(
                "el-form",
                {
                  ref: "form",
                  staticStyle: { padding: "0" },
                  attrs: { inline: true, "label-width": "140px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "通道名称：" } },
                    [
                      _c("el-input", {
                        staticStyle: {},
                        attrs: {
                          disabled: true,
                          placeholder: "请输入通道名称",
                        },
                        model: {
                          value: _vm.form.parkChannelName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "parkChannelName", $$v)
                          },
                          expression: "form.parkChannelName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "通道编码：" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "300px" },
                        attrs: {
                          disabled: true,
                          placeholder: "请输入通道编码",
                        },
                        model: {
                          value: _vm.form.parkChannelCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "parkChannelCode", $$v)
                          },
                          expression: "form.parkChannelCode",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "出入口类型：" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: {},
                          attrs: { placeholder: "请选择", disabled: true },
                          model: {
                            value: _vm.form.channelType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.form,
                                "channelType",
                                typeof $$v === "string" ? $$v.trim() : $$v
                              )
                            },
                            expression: "form.channelType",
                          },
                        },
                        _vm._l(_vm.channelList, function (value) {
                          return _c("el-option", {
                            key: value.code,
                            attrs: { label: value.desc, value: value.code },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("h2", { staticClass: "title" }, [
                _c("div", { staticClass: "title_icon" }),
                _vm._v("管理设备 "),
              ]),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    "header-cell-class-name": "header-call-style",
                    data: _vm.tableData,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      label: _vm.$t("list.index"),
                      width: "70",
                      align: "center",
                    },
                  }),
                  _vm._l(_vm.tableCols, function (item) {
                    return _c("el-table-column", {
                      key: item.prop,
                      attrs: {
                        prop: item.prop,
                        label: item.label,
                        width: item.width,
                        formatter: item.formatter,
                        align: "center",
                      },
                    })
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label:
                        _vm.details.parkSystemType == 0 ? "设备状态" : "状态",
                      align: "center",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _vm.details.parkSystemType == 0
                              ? _c(
                                  "div",
                                  {
                                    style: {
                                      color:
                                        scope.row.equipActualState != "离线"
                                          ? "green"
                                          : "red",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(scope.row.equipActualState)
                                    ),
                                  ]
                                )
                              : _c(
                                  "span",
                                  {
                                    class: _vm._f("colorFilter")(
                                      scope.row.equipmentState
                                    ),
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm._f("stateFilter")(
                                          scope.row.equipmentState
                                        )
                                      )
                                    ),
                                  ]
                                ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c("el-button", { on: { click: _vm.resetForm } }, [
                _vm._v("返回"),
              ]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }